<template>
  <div class="text-justify md:text-left lg:text-xl">
    <template v-if="entry.name">
      <div v-if="index === 'fsi'">
        <span class="font-medium">{{ entry.name }}</span> is ranked in position
        <span class="font-medium">{{ entry.rank }}</span> in the <span class="font-medium">{{ year }}</span> Financial
        Secrecy Index, based on a {{ secrecyScale(entry.score) }} secrecy score of
        <span class="font-medium">{{ entry.score }}</span> and a {{ weightScale(entry.globalScaleWeight) }} global scale
        weight for the size of offshore financial services of
        <span class="font-medium">{{ `${Math.round(entry.globalScaleWeight * 1000) / 10} %` }}</span
        >.
      </div>
      <div v-else>
        <span class="font-medium">{{ entry.name }}</span> is ranked in position
        <span class="font-medium">{{ entry.rank }}</span> in the <span class="font-medium">{{ year }}</span> Corporate
        Tax Haven Index, based on a {{ secrecyScale(entry.score) }} haven score of
        <span class="font-medium">{{ round(entry.score) }}</span> and a
        {{ weightScale(entry.globalScaleWeight) }} global scale weight of
        <span class="font-medium">{{ `${round(entry.globalScaleWeight * 100)} %` }}</span
        >.
      </div>
      <div class="flex flex-row justify-between text-base pt-4">
        <div>
          <router-link :to="backUrl" class="text-light-base underline">go back</router-link>
        </div>
        <div>
          <a :href="readMoreUrl" target="_blank" class="text-light-base underline" v-if="iso2">Read more...</a>
        </div>
      </div>
    </template>

    <div v-else-if="countryName">{{ countryName }} is not included in the {{ year }} index.</div>

    <div v-html="contryDesc" class="mb-8" v-if="false" />
    <indicator-legend v-if="false" />
  </div>
</template>
<script>
import IndicatorLegend from '@/components/IndicatorLegend'
import { mapGetters } from 'vuex'
import i18n from '../utils/locales'
import { dimensionKey, secrecyScale as sScale } from '@/utils/'
import { round } from '@/utils/numbers'
import { scaleThreshold } from 'd3-scale'

const weightScale = scaleThreshold()
  // .domain([0.0009, 0.009, 0.049])
  .domain([0.001, 0.01, 0.05])
  .range(['tiny', 'small', 'large', 'huge'])

// const secrecyScale = scaleThreshold()
//   .domain([50, 60, 70, 80, 90])
//   .range(['low', 'medium', 'fairly high', 'high', 'very high', 'extremely high'])

export default {
  components: {
    IndicatorLegend
  },
  computed: {
    ...mapGetters(['country', 'getFsiById', 'getCountryById', 'year', 'index', 'getIso2', 'dimension', 'selection']),
    countryName() {
      const name = this.getCountryById(this.country)
      if (name) {
        return name.properties.SOVEREIGNT
      }
      return null
    },
    iso2() {
      return this.getIso2(this.country)
    },
    readMoreUrl() {
      return `https://cthi.taxjustice.net/en/cthi/profiles?country=${this.iso2}`
    },
    backUrl() {
      return `/${this.index}/${this.year}/world/${this.dimension}/${this.selection}`
    },
    secrecyScaleMethod() {
      return sScale(this.index)
    },
    entry() {
      const country = this.getFsiById(this.country)
      const scoreKey = dimensionKey('score', this.index)
      if (country) {
        return {
          name: country.Jurisdiction,
          rank: country.Rank,
          score: country[scoreKey],
          globalScaleWeight: country.Global_Scale_Weight
        }
      }
      return {}
    },
    ...i18n.map([
      'CHE',
      'USA',
      'CYM',
      'HKG',
      'SGP',
      'LUX',
      'DEU',
      'TWN',
      'ARE',
      'GGY',
      'GBR',
      'AUS',
      'AUT',
      'CAN',
      'CUW',
      'LBR',
      'NLD',
      'ZAF',
      'ABW',
      'BHR',
      'BEL',
      'VGB',
      'CYP',
      'DNK',
      'GMB',
      'GHA',
      'GTM',
      'KEN',
      'LBN',
      'MUS',
      'NZL',
      'PAN',
      'TUR',
      'VUT'
    ]),
    contryDesc() {
      return this.country ? this[this.country] : ''
    }
  },
  methods: {
    round,
    secrecyScale(d) {
      return this.secrecyScaleMethod(d) // secrecyScale(d)
    },
    weightScale(d) {
      return weightScale(d)
    }
  }
}
</script>
