import VI18n from '../utils/vi18n'

const texts = {
  en: {
    pageTitle: 'Financial Secrecy Index',
    index: {
      lead: "Ranks the world's largest contributors to global financial secrecy.",
      // "Ranks the world's largest contributors to global financial secrecy, by combining secrecy score and market share (GSW).",
      more: [
        'The Financial Secrecy Index (FSI) is the world’s most comprehensive assessment of the secrecy of financial centres and the impact of that secrecy on global financial flows.'
      ],
      simple_more: [
        'The Financial Secrecy Index (FSI) is the world’s most comprehensive assessment of the secrecy of financial centres and the impact of that secrecy on global financial flows.'
      ]
    },
    score: {
      lead: 'Measurement of financial secrecy in each jurisdiction.',
      more: [
        'The secrecy score is measured by 20 Key Financial Secrecy Indicators (KFSI). Criterias include rules on transparency on ownership of companies, trusts and foundations, public access to annual accounts, and compliance with anti-money laundering standards.'
      ]
    },
    globalscaleweight: {
      lead: 'Share of the global market of offshore financial services',
      more: [
        'Countries and territories can sell financial services to people and companies outside the country (non-residents). Each jurisdiction’s share of the global market of such financial services is calculated. This quantative measure provides the Global Scale Weight.'
      ]
    },
    highlights: {
      top: 'Highlights the ten most significant secrecy jurisdictions.',
      uk: 'Highlights the UK and Overseas Territories, Crown Dependencies and other Commonwealth Territories.',
      oecd: 'Highlights OECD countries.'
    },
    simple_footer: 'Access the full database, country reports and methodology at '
  }
}

const i18n = new VI18n({
  base: 'en-us', // The base fallback when specified language not exists, defaults to 'en-us'
  locale: 'en-us', // Global language, defaults to browser language
  locales: {
    'en-us': {
      __name__: 'English',
      pageTitle: texts.en.pageTitle,
      lead_index: texts.en.index.lead,
      lead_score: texts.en.score.lead,
      lead_globalscaleweight: texts.en.globalscaleweight.lead,
      more_fsi: `<p>${texts.en.index.more.join('</p><p>')}</p>`,
      simple_more_fsi: `<p>${texts.en.index.simple_more.join('</p><p>')}</p>`,
      more_ss: `<p>${texts.en.score.more.join('</p><p>')}</p>`,
      more_gsw: `<p>${texts.en.globalscaleweight.more.join('</p><p>')}</p>`,
      hl_top: texts.en.highlights.top,
      hl_uk: texts.en.highlights.uk,
      hl_oecd: texts.en.highlights.oecd,
      footer: `${texts.en.simple_footer} <a href="http://www.financialsecrecyindex.com">www.financialsecrecyindex.com</a>`,

      CHE:
        '<p>Switzerland is ranked in first position in the 2018 Financial Secrecy Index, based on a high secrecy score of 76 and a large global scale weight for the size of offshore financial services (approximately five percent of the global market). Its famed banking secrecy laws remain firmly in place, though with exceptions permitted for some countries to obtain necessary information.</p>',
      USA:
        '<p>The United States is ranked second in the 2018 Financial Secrecy Index.</p><p>The rise of the US continues a long term trend, as the country was one of the few to increase their secrecy score in the 2015 index. The US has increased their share of the global market for offshore financial services by 14%  between 2015 and 2018.</p>',
      CYM:
        '<p>Cayman is ranked third on the 2018 Financial Secrecy Index, based on a high secrecy score of 72 and a large global scale weight for the size of offshore financial services, almost 4% of the world total. </p><p>The Cayman Islands is an Overseas Territory of the United Kingdom. As such, it operates with considerable political and economic autonomy from the UK, but with a strong degree of support and oversight from the UK.</p>',
      HKG:
        '<p>Hong Kong is in fourth position in the 2018 Financial Secrecy Index. It has a fairly high secrecy score of 71 out of 100, accounts for 4.17 percent of the global market for offshore financial services, and is growing.</p><p>Hong Kong is one of the world’s fastest growing secrecy jurisdictions or tax havens today. On paper, Hong Kong accounted for just under half of all foreign investment into China at the end of 2012, according to IMF data. Much of this was round-tripped Chinese capital, however.</p>',
      SGP:
        '<p>Singapore is ranked in fifth position on the 2018 Financial Secrecy Index. It has a fairly high secrecy score of 67 and accounts for a large and growing share – over four percent – of the global market for offshore financial services.</p><p>This former British colony vies with Hong Kong to be Asia’s leading offshore financial centre: Singapore predominantly serves Southeast Asia while Hong Kong predominantly serves China and North Asia. </p>',
      LUX:
        '<p>The Grand Duchy of Luxembourg is ranked sixth in our 2018 Financial Secrecy Index, based on a moderate secrecy score of 58 and a very large share of the market for offshore financial services, at over 12 percent of the global total. Its’ position is largely unchanged since our 2015 index.</p><p>Positioned at the heart of Europe, this tiny constitutional monarchy has a population of just over half a million, allowing its enormous financial sector to achieve a strong degree of ‘capture’ over the political system, media and culture. Criticism of the finance sector, and discussion about its relationship to society, used to be exceedingly rare, though the LuxLeaks revelations in 2014 are helping to change this.</p>',
      DEU:
        '<p>Germany is ranked 7 in the 2018 Financial Secrecy Index (FSI), based on a moderate secrecy score of 59.1 combined with a large global scale weight: Germany accounts for over 5% of the global market for offshore financial services.</p><p>The German government has had a mixed record in terms of taking on financial secrecy. Germany has in recent years taken important steps to fight tax evasion and money laundering both internationally and nationally. However, serious loopholes remain in national legislation and negligent enforcement of tax and anti-money laundering regulations still pose a threat to their effectiveness.</p>',
      TWN:
        '<p>Taiwan is a tax haven that for many years has managed to successfully avoid scrutiny.</p><p>The first detailed analysis of Taiwan as an offshore centre emerged in July 2017 with the publication of the analysis of offshore sinks and conduits compiled by the CORPNET group of researchers at the University of Amsterdam. Researchers found that Taiwan was a significant sink jurisdiction, one of the most prominent destinations for offshore capital in the world.  TJN started covering Taiwan in our 2015 FSI. In that edition the amount of data we could find on the country was too poor for it to make it onto our main ranking. This year, having undertaken a full review of Taiwan’s legal system the country makes it in to the top ten.</p>',
      ARE:
        '<p>The United Arab Emirate of Dubai is ranked 9th in the 2018 Financial Secrecy Index, based on a small, but growing scale weighting of 0.1 combined with a notably high secrecy score of 84. </p><p>Dubai is one of seven of the United Arab Emirates (UAE), and hosts an important secrecy jurisdiction and offshore financial centre based in Dubai City. </p><p>This is based primarily on financial flows from an oil-rich region; but also in its historical role as a trading entrepôt parked conveniently between Europe and a fast-growing Asia, making Dubai, among other things, India’s biggest trading partner, at least on paper.</p>',
      GGY:
        '<p>Guernsey, a UK Crown Dependency, is in 10th place in the 2018 Financial Secrecy Index, up 7 places from 2015. The small tax haven jurisdiction in the English Channel gained a secrecy score of 72 out of 100, a significant increase from 2015 when it scored 64. It accounts for 0.5% of the global trade in offshore financial services according to the FSI global scale weight methodology.</p>',
      GBR:
        '<p>The United Kingdom is ranked 23rd on the 2018 Financial Secrecy Index, based on a low secrecy score of 42 and a very large scale weighting, accounting for 17 percent of the global market in offshore financial services. The United Kingdom’s relatively low ranking on the secrecy index hides a much bigger story. We regard the UK as one of the biggest, if not the biggest, single player in the global offshore system of tax havens (or secrecy jurisdictions) today.</p><p>There are two reasons for the discrepancy between its ranking and its importance. The first is that the City of London, or “the City”, a term used to describe the UK financial services industry centred on London, is on some measures the world’s largest financial centre.  The second is that the UK is intricately connected to a large network of British secrecy jurisdictions around the world, notably the three Crown Dependencies (Jersey, Guernsey and the Isle of Man) and the 14 Overseas Territories, which include such offshore giants as Cayman, the British Virgin Islands and Bermuda. </p>',
      AUS:
        '<p>Australia, not traditionally regarded as a secrecy jurisdiction, is ranked at 45th position in the 2018 Financial Secrecy Index, one place down from its 2015 position, 44. </p><p>Australia has been assessed with a secrecy score of 51 out of a potential 100, which places it in the lower mid-range of the secrecy scale. Australia accounts for less than 1 per cent of the global market for offshore financial services, making it a tiny player compared with other secrecy jurisdictions.',
      AUT:
        '<p>Austria is ranked at 35th position on the 2018 Financial Secrecy Index, based on a moderate secrecy score of 56, and a small scale weighting of 0,56 percent of the global market for offshore financial services. </p><p>The secrecy score has increased slightly, from 54 in the 2015 Financial Secrecy Index, however, the FSI methodology has also changed to be more demanding than the previous index. Austria’s importance as a secrecy jurisdiction for non-residents has stemmed primarily from its secretive private foundations and Treuhand (a form of Austrian trust), and an absence of inheritance and other taxes.But some recent transparency improvements are showing signs that Austria could be partly turning away from a secrective past.',
      CAN:
        '<p>Canada’s role in the offshore system has three main components. First, compared to its peers it has an extremely low effective tax rate on businesses. Second, it is effectively a regulatory haven for the world’s extractive industries. Third, Canadian interests played a significant part in the development of tax havens elsewhere, notably in the Caribbean. Canada also offers some secrecy facilities.</p><p>With a secrecy score of 55 out of 100, Canada ranks among the less secretive jurisdictions on the Financial Secrecy Index list, though there is still room for improvement.',
      CUW:
        '<p>Curaçao is ranked in 84th position in the 2018 Financial Secrecy Index, based on a high secrecy score of 75, but a tiny global scale weight for the size of offshore financial services.</p><p>Curaçao is a country of the Kingdom of the Netherlands. Constitutional changes in 2010, involved the dissolution of the Netherlands Antilles, which Curaçao previously was a part of. Curaçao lies about 70 km north of the coast of Venezuela, has about 140.000 inhabitants and is fully autonomous in internal affairs. The Netherlands manages external affairs and defence.',
      LBR:
        '<p>Liberia’s secrecy jurisdiction is an example of where state sovereignty has been outsourced, almost wholesale to foreign interests. In setting up its corporate registry, the Liberian government have effectively privatised a key function of the state and even it appears some parts of Liberian law itself.</p><p>The result is that Liberia permits the establishment of some of the most powerful secrecy instruments in the world, which is reflected in its secrecy score of 80.</p><p>Although Liberia’s reputation as a secrecy jurisdiction has threatened to cause substantial damage to the reputation of country abroad, very little is known about Liberia’s tax haven role in the country itself. Its offshore corporate registry is located and managed in the United States.',
      NLD:
        '<p>The Netherlands is one of the big risers in the 2018 Financial Secrecy Index, hitting 14th position – up from 41st in the 2015 index. </p><p>vThe rise in the Netherlands’ FSI ranking has been driven by an increase in its secrecy score. It has a score of 66 which is an increase of 17.5 points from the 2015 FSI. Although the Netherlands is famous for being a corporate tax haven, and trillions of Euros of corporate profits flow though the country each year, this is not picked up in our measure of the size of the offshore sector. The FSI does not measure financial flows routed though subsidiaries of multinational companies, which are internal to those companies. Under the FSI methodology, the Netherlands accounts for less than 1 per cent of the global market for offshore financial services."',
      ZAF:
        '<p>South Africa’s secrecy score of 56.10 is the lowest secrecy score of the nine African jurisdictions included in the Financial Secrecy Index 2018. Yet its global significance is the greatest of any of the African countries, reflecting the relative size of South Africa’s economy.</p><p>Secrecy undermines South Africa’s own tax base. The country’s elite, and South African and foreign multinational companies within its borders exploit weaknesses in legislation and use other secrecy jurisdictions to reduce their tax obligations in a country with deep inequality.',
      ABW:
        '<p>Aruba is found in 68th place on the 2018 Financial Secrecy Index. Although the country has a relatively high secrecy score, of 76, it accounts for a tiny proportion of offshore financial services. The offshore sector in Aruba never got off the ground, with the offshore sector being dominated by its larger neighbour, Curacao',
      BHR:
        '<p>Bahrain is ranked at 17th position on the 2018 Financial Secrecy Index. It has a high secrecy score of almost 78 out of 100, though it accounts for only a small share of the global market for offshore financial services. Bahrain is nominally a constitutional monarchy, though the ruling Al-Khalifa family has been accused of dictatorial tendencies, and Bahrain has seen its fair share of political repression.</p><p>Lacking any major remaining oil reserves, Bahrain has made up for this in part by building up an offshore financial centre. The financial sector today is the largest employer in Bahrain, contributing to over 25 percent of GDP and employing some 14,000 people. </p><p>Bahrain is one of the biggest global centres for Islamic finance, and hosts the largest concentration of Islamic finance institutions in the Middle East.',
      BEL:
        '<p>Belgium comes in at number 53 in the 2018 Financial Secrecy Index.</p><p>Belgium has always had a relatively large financial services industry given the size of the country, but recent improvements in financial transparency means it has a relatively low secrecy score of 44. This is a welcome change for a country that previously had strong secrecy laws.</p><p>Although the use of Belgium as a secrecy jurisdiction has declined, there remain issues for domestic tax inspectors accessing Belgian accounts. The country also remains a corporate tax haven.',
      VGB:
        '<p>The British Virgin Islands (BVI) is ranked at 16th position in the 2018 Financial Secrecy Index. It has a relatively high secrecy score of 68.65, though it accounts for only a small share of the global market for offshore financial services. Yet its relatively low ranking in our index seriously understates its true importance in the world of offshore secrecy. </p><p>BVI financial secrecy comes most importantly from their lax, flexible, ask-no-questions, see-no-evil company incorporation regime, which allows owners of companies to hide behind ‘nominees’ to achieve strong secrecy, and to set up companies quickly and at low cost. This supposed ‘efficiency’ of incorporation has translated into carte blanche for BVI companies to hide and facilitate all manner of crimes and abuses, worldwide.</p><p>The BVI is now the world’s leading centre for company incorporation, with a thriving industry selling corporate secrecy and over a million shell companies incorporated since landmark legislation was introduced in 1984. In 2017, there were 417,000 active companies: 18 for each inhabitant. Half of the companies set up by Mossack Fonseca identified in the Panama Papers were incorporated in the BVI.',
      CYP:
        '<p>Cyprus is ranked at 24th position in the 2018 Financial Secrecy Index.  it has a fairly high secrecy score of 67, though it accounts for only a small share of the global market for offshore financial services.</p><p>For Cyprus, establishing itself as an international centre for business and finance has been an important point on the political agenda particularly since the invasion of the northern parts of the island by Turkey in 1974. The country has limited potential for industry, little agriculture and are heavily reliant on tourism, therefore finance and business services have been a rare growth sector providing good high-paying jobs for an increasingly educated and specialised workforce. As a result, the financial sector played and continues to play a dominant role in the Cypriot economy. Cyprus’ banking sector assets were estimated to be equivalent to more than 900 percent of GDP in 2010, at the height of the expansion of the Cypriot financial sector. This number fell to to under 500 percent by 2013, but still remains considerably higher than the Euro Area Average.',
      DNK:
        '<p>Denmark is a high-tax nation characterised by high social trust and strong tax compliance. Recent Danish governments have been firmly in favour of combating financial secrecy. While issues remain, a succession of reforms over the past decades in particular have ensured that Denmark today has relatively few characteristics of secrecy jurisdictions. Still, tax evasion and avoidance have increasingly come under serious public scrutiny in the wake of media attention, tax haven leaks and international political action.',
      GMB:
        '<p>The Gambia has a high secrecy score of 76.63, which makes it the 9th most secretive African Country and the 3rd most secretive West African country. It accounts for a tiny proportion of offshore financial services which is the main reason it is ranked very low at the 2018 FSI, at 106th position. </p><p>The small West African country, The Gambia, may be described as a typical tax haven, not necessarily because of its financial secrecy or favourable tax regimes, but because of its exotic geographical features: a small country, with a 60-kilometre beachfront that makes it an attractive tourist destination. Banking started 100 years ago in The Gambia; it has thirteen conventional commercial banks and one Islamic bank. A former British colony, it attained independence on 18 February 1965 and uses a currency called ‘dalasi’.',
      GHA:
        '<p>Ghana has long been touted as an inspiration for true multi-party democracy and a nation with a fairly well-performing economy in sub-Saharan Africa. Over a decade ago, Ghana formally initiated and eventually became a secrecy jurisdiction – an International Financial Services Centre (IFSC) was created and Barclays Bank Ghana Limited (BBGL) was granted the only offshore banking licence in the country. Ultimately, Ghana failed in its quest to become a secrecy jurisdiction, less than five years after BBGL was granted an offshore banking licence. However, the framework that established the IFSC still exists on Ghana’s law books. Currently, there are plans to revive the IFSC in 2018.',
      GTM:
        '<p>For Guatemala, a country with a history of military rule and civil war, corruption and violance, financial transparency was not high on the agenda for along time. However, after the Civil War ended in 1996 the country has slowly started to get back on its feet, and since the mid-2000s, Guatemala has made some efforts to comply with international transparency standards, and to remove itself from international blacklists.</p><p>These efforts have intensified over the last couple of years, resulting in the removal of Guatamela from the OECD blacklist in 2017. However, with a secrecy score of 73 in the 2018 FSI, the country still has along way to go</p><p>Guatemala is in 78th position in the 2018 FSI. Athough the country has a relatively high secrecy score of 73, it has a tiny market for offshore financial services. However, the poor state of finaincial regulation in the country remains highly damaging for the administration of government in the country.',
      KEN:
        '<p>Kenya’s financial sector is highly secretive. The country scored 80 out of 100 in terms of secrecy, which explains its high ranking of 27 in the Financial Secrecy Index of 2018. Though the country’s share of the offshore world is not large, this may be set to increase as the government positions Nairobi as the latest African International Financial Centre.',
      LBN:
        '<p>Lebanon is ranked eleventh on the 2018 Financial Secrecy Index, based on a very high secrecy score of 72, and a low scale weighting for the size of its offshore financial services sector, at 0.5 percent of the world total. </p><p>Lebanon has historically had a large offshore financial sector, catering mostly to Arab countries and to the large and successful Lebanese. The diaspora is so extensive that its members outnumber Lebanon’s own population by a significant margin, and the IMF identifies it, alongside banking secrecy, currency convertibility and high interest rates, as a cornerstone of a banking sector that is unusually large for a country of Lebanon’s income level.',
      MUS:
        '<p>The islands of the Republic of Mauritius lie just 2,000 kilometres off the southeast coast of Africa. Mauritius has a fairly high secrecy score of 72, and accounts for a small share of the global market for offshore financial services.  </p><p>Positioned as the ‘gateway to Africa’, Mauritius’ secrecy score of 72.35 in the Financial Secrecy Index 2018 reflects the nation’s ongoing contribution to illicit financial flows from some of the countries that require the public finances the most. In entering double taxation agreements with 43 nations, 16 of which are African states, Mauritius enables companies and individuals to reduce their tax bill across the world. India and many African nations suffer as a result. Mauritius is in the process of ratifying, signing, or negotiating double taxation agreements with a further 14 African countries. Of its 11 Tax Information Exchange Agreements, none are signed with African jurisdictions.',
      NZL:
        '<p>New Zealand is not a major tax haven or financial secrecy jurisdiction. It is an extremely small player in the global market for offshore financial services when compared with other secrecy jurisdictions, accounting for 0.1 per cent of the market. It sits relatively low on the Financial Secrecy Index’s scale of Key Financial Service Indicators (KSFI), with a score of 56.23 in 2018.',
      PAN:
        '<p>Panama ranks at 12th position on the 2018 Financial Secrecy Index, with a high secrecy score of 76.6 but a small global scale weighting (0.27%). Coming within the top twenty ranking, Panama remains a jurisdiction of particular concern. </p><p>Long the recipient of drugs money from Latin America, plus ample other sources of dirty money from the U.S.A. and elsewhere, it is one of the oldest and best known tax havens in the Americas. In recent years it has adopted a hard-line position as a jurisdiction that refuses to co-operate with international transparency initiatives. </p><p>In April 2016, in the biggest leak ever, 11.5 million documents from the Panama law firm Mossack Fonseca revealed the extent of Panama’s involvement in the secrecy business. The Panama Papers showed the world what a few observers had long been saying: that the secrecy available in Panama makes it one of the world’s top money laundering locations.',
      TUR:
        '<p>Turkey is ranked at 30th position in the 2018 Financial Secrecy Index. It has a relatively high secrecy score of 68, though it accounts for only a small share of the global market for offshore financial services. </p><p>Turkey lies between Europe and Asia and has borders with Balkan countries (Bulgaria and Greece), Caucasian counties (Azerbaijan, Georgia, Armenia) and the Middle East (Iran, Iraq, Syria). This geopolitical position makes Turkey vulnerable to terrorist activities, smuggling, human trafficking and war. </p><p>Turkey joined the Financial Action Task Force (FATF) in 1991 and membership has contributed extensively to improvements in the legal, regulatory and operational measures for combating money laundering, terrorist financing and other related threats in the country – although progress has been slow. In 2012 Turkey almost saw its membership of the group suspended, and it was only removed from the list of high risk jurisdictions in 2014.',
      VUT:
        '<p>Vanuatu is ranked at 89th position in the 2018 Financial Secrecy Index. It has a high secrecy score of 89, though it accounts for only a tiny share of the global market for offshore financial services. </p><p>Vanuatu creates a congenial environment for an offshore financial secrecy centre which makes significant contributions to its small local economy. Its subculture plays an important role in the country’s domestic politics. It contains few domestic interest groups which are inclined to oppose tax haven development.</p><p>Although Vanuatu has a relatively small tax haven or offshore financial centre (OFC), it can still have a major impact. A recent example arose in July 2017, when a Vanuatu-registered company IGOFX was reported to be at the centre of a 30 billion yuan ($4.4 billion) ponzi swindle (with its appealing slogan for investors ‘Lie to make money.’) It allegedly vic¬timised more than 400,000 Chinese and Chinese Malaysians of modest means—leading to desperation and even suicide when they could not get their money back."'
    }
  }
})

export default i18n
