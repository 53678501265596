<template>
  <div class="indicator-legend">
    <h2>Key Financial Secrecy Indicators Legend</h2>
    <ol class="align-left">
      <li v-for="indicator in indicators" :key="indicator.number">
        <strong>{{ indicator.name }}:</strong> {{ indicator.description }}
      </li>
    </ol>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState({
    indicators: state => state.indicators.list
  })
}
</script>
